<template>
    <div v-cloak>
        <transition name="fade" appear appear-active-class="fade-enter-active">
            <div v-if="apiLoaded || errorText">
                <div v-if="apiLoaded" class="wrap docs">
                    <h1>Настройка состава полей</h1>

                    <div class="docs-table">
                        <table>
                            <tr>
                                <th class="doc-title">Название</th>
                                <th class="doc-title">Целевые категории</th>
                                <th class="doc-checkbox doc-checkbox__wide">Отображение в каталоге</th>
                                <th class="doc-checkbox">Публичность</th>
                                <th class="doc-del"></th>
                            </tr>
                            <tr v-for="(field, fieldIndex) in fields" :key="'field' + fieldIndex">
                                <td>{{field}}</td>
                                <td>
                                    <v-autocomplete
                                            dense
                                            :items="categories"
                                            item-text="name"
                                            return-object
                                    ></v-autocomplete>
                                </td>
                                <td class="doc-checkbox doc-checkbox__wide">
                                    <v-checkbox></v-checkbox>
                                </td>
                                <td class="doc-checkbox">
                                    <v-checkbox></v-checkbox>
                                </td>
                                <td class="doc-del"></td>
                            </tr>
                        </table>
                    </div>

                    <div class="doc-add">
                        <a href="/">Добавить поле</a>
                    </div>
                    <div class="buttons">
                        <v-btn
                                class="btn btn-outline"
                                text
                                @click="$emit('close')"
                        >
                            Отмена
                        </v-btn>
                        <v-btn
                                class="btn btn-primary"
                                @click="$emit('close')"
                        >
                            Сохранить изменения
                        </v-btn>
                    </div>
                </div>
                <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
                    Ошибка: {{ errorText }}
                </div>
            </div>
            <div v-else style="height:1000px"></div>
        </transition>
    </div>
</template>

<script>

    export default {
        name: 'ServiceCZNConfig',
        components: {},

        data: () => ({
            id: null,
            values: {},
            categories: [
                'Все',
                'Реестр прототипов сервисов',
                'Реестр сервисов для пилотной апробации',
                'Реестр рекомендуемых сервисов',
                'Реестр действующих сервисов',
                'Реестр архивных сервисов',
            ],
            fields: [
                'Наименование сервиса',
                'Краткое наименование сервиса',
                'Категория сервиса',
                'Услуга, в рамках которой предоставляется сервис',
                'Назначение сервиса',
                'Результаты реализации сервиса',
                'Сроки реализации сервиса',
                'Тег',
            ],
            apiLoaded: false,
            errorText: null,
            dialog: false,
            editable: false,
        }),

        methods: {},

        filters: {},

        async beforeMount() {
            this.apiLoaded = true;
        }
    }
</script>

<style lang="scss">
    @import "../styles/main.scss";
</style>
